import React from 'react';
import { Link } from 'react-router-dom';


const Header = () => {
  return (
    <header className="flex justify-between items-center py-4 px-8 bg-blue-500 text-white">
      <Link to="/" className="text-2xl font-bold cursor-pointer">
        Sachin Dharashivkar
      </Link>
      <nav className="flex items-center">
        <Link to="/projects" className="text-lg font-semibold mr-4">
          Projects
        </Link>
        <Link to="/books" className="text-lg font-semibold mr-4">
          Books
        </Link>
        <Link to="https://a16z.com/the-techno-optimist-manifesto/" className="text-lg font-semibold mr-4" target="_blank">
          Politics
        </Link>
      <a
        href="/resume.pdf"
        target="_blank"
        rel="noopener noreferrer"
        className="text-lg font-semibold"
      >
        Resume
      </a>
    </nav>
    <h1 className="absolute left-[-9999px]">Sachin Dharashivkar is a hitchhiker. He is currently residing on earth and searching his lost towel.</h1>
    </header>
  );
};

export default Header;