import React from 'react';

const Books = ({ footerHeight }) => {
  return (
<main className="flex mt-16 mx-8">
  <div className="ml-8">
    <h1 className="text-2xl font-bold">Books I would recommend:</h1>
    <h2 id="non-fiction" className="text-lg font-bold mt-6">Non-fiction</h2>
    <ul className="list-disc list-inside mt-2">
      <li>
        <a href="https://www.amazon.in/Snowball-Warren-Buffett-Business-Life/dp/0553384619" className="text-blue-500 hover:underline">
          Snowball: Warren Buffett and the Business of Life
        </a> by Alice Schroeder
      </li>
      <li>
        <a href="https://www.amazon.in/Poor-Charlies-Almanack-Wisdom-Charles/dp/1578645018" className="text-blue-500 hover:underline">
          Poor Charlie&apos;s Almanack: The Wit and Wisdom of Charles T. Munger
        </a> by Charlie Munger
      </li>
      <li>
        <a href="https://www.amazon.in/Steve-Jobs-Walter-Isaacson/dp/034914043X" className="text-blue-500 hover:underline">
          Steve Jobs
        </a> by Walter Isaacson
      </li>
      <li>
        <a href="https://www.amazon.in/influence-Psychology-Persuasion-Business-Essentials/dp/006124189X" className="text-blue-500 hover:underline">
          Influence: The Psychology of Persuasion
        </a> by Robert Cialdini
      </li>
      <li>
        <a href="https://www.amazon.com/How-Win-Friends-Influence-People/dp/0671027034" className="text-blue-500 hover:underline">
          How to win friends and influence people
        </a> by Dale Carniege
      </li>
      <li>
        <a href="https://www.amazon.in/Mans-Search-Meaning-Viktor-Frankl/dp/1846041244" className="text-blue-500 hover:underline">
          Man&apos;s Search for Meaning
        </a> by Viktor E. Frankl
      </li>
      <li>
        <a href="https://www.amazon.in/Hackers-Painters-Paul-Graham/dp/1449389554" className="text-blue-500 hover:underline">
          Hackers and Painters
        </a> by Paul Graham
      </li>
      <li>
        <a href="https://en.wikipedia.org/wiki/The_Power_Broker" className="text-blue-500 hover:underline">
          The Power Broker
        </a> by Robert Caro
      </li>
    </ul>
    <h2 id="fiction" className="text-lg font-bold mt-6">Fiction</h2>
<ul className="list-disc list-inside mt-2">
  <li>
    <a href="https://www.amazon.com/Hitchhikers-Guide-Galaxy-Douglas-Adams/dp/0345391802" className="text-blue-500 hover:underline">
      Hitchhiker&apos;s Guide to the Galaxy
    </a> by Douglas Adams
  </li>
  <li>
    <a href="https://en.wikipedia.org/wiki/A_Song_of_Ice_and_Fire" className="text-blue-500 hover:underline">
      A Song of Ice and Fire
    </a> by George RR Martin
  </li>
  <li>
    <a href="https://www.amazon.in/Fountainhead-Ayn-Rand-ebook/dp/B002OSXDAU" className="text-blue-500 hover:underline">
      The FountainHead
    </a> by Ayn Rand
  </li>
  </ul>

    </div>
    </main>
  );
};

export default Books;