import React, { useRef, useEffect } from 'react';
import { FaPhoneAlt, FaEnvelope, FaTwitter, FaLinkedin } from 'react-icons/fa';

const Footer = ({ setFooterHeight }) => {
  const footerRef = useRef(null);

  useEffect(() => {
    if (footerRef.current) {
      setFooterHeight(footerRef.current.offsetHeight);
    }
  }, [setFooterHeight]);
  return (
    <footer ref={footerRef} className="flex justify-center items-center py-4 bg-gray-800 text-white" style={{ position: 'fixed', bottom: 0, width: '100%' }}>

      <a href="tel:+91-9420377689" className="mx-4">
        <FaPhoneAlt size={24} />
      </a>
      <a href="mailto:sachin.dharashivkar@gmail.com" className="mx-4">
        <FaEnvelope size={24} />
      </a>
      <a href="https://twitter.com/s16r442" target="_blank" rel="noopener noreferrer" className="mx-4">
        <FaTwitter size={24} />
      </a>
      <a href="https://www.linkedin.com/in/sachin-dharashivkar/" target="_blank" rel="noopener noreferrer" className="mx-4">
        <FaLinkedin size={24} />
      </a>
      <a href="https://sachindharashivkar.substack.com/" target="_blank" rel="noopener noreferrer" className="mx-4">
        <img src="/substack-white.svg" alt="Substack" width={24} height={24} />
      </a>
    </footer>
  );
};

export default Footer;