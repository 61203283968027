import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Header';
import MainContent from './MainContent';
import Footer from './Footer';
import Books from './Books';
import Projects from './Projects';

function App() {
  const [footerHeight, setFooterHeight] = useState(0);
  return (    <Router>
      <div className="App">
        <Header />
        <div className="MainContent mb-16">
        <Routes>
          <Route exact path="/" element={<MainContent footerHeight={footerHeight} />} />
          <Route path="/books" element={<Books footerHeight={footerHeight} />} />
          <Route path="/projects" element={<Projects footerHeight={footerHeight} />} />
        </Routes>
        </div>
        <Footer setFooterHeight={setFooterHeight} />
      </div>
    </Router>
  );
}

export default App;
