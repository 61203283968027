import React from 'react';
import profilePhoto from './sachin1.JPG';

const MainContent = ({ footerHeight }) => {

  return (
    <main className="flex mt-16 mx-8" style={{ paddingBottom: `${footerHeight}px` }}>

      <img src={profilePhoto} alt="Sachin Dharashivkar" className="w-1/3 h-1/2 rounded-lg" />
      <div className="ml-8">
        <p className="text-lg mb-4" title="Introduction">
          Hello, my friends! I am Reinforcement Learning enthusiast who have built agents to <a href="/projects/#overcooked" className="text-blue-500 hover:underline cursor-pointer">play games</a> and <a href="/projects/#tradebot" className="text-blue-500 hover:underline cursor-pointer">trade stocks</a>. Being solo founder, I have built a <a href="/projects/#intellawyer" className="text-blue-500 hover:underline cursor-pointer">semantic legal search engine</a> and a <a href="/projects/#aivetaal" className="text-blue-500 hover:underline cursor-pointer">framework</a> for assessing student's understanding of different subjects. I am building web penetration testing agent at AthenaAgent. Want to know more about me? Please have a look at my past <a href="/projects" className="text-blue-500 hover:underline cursor-pointer">projects</a> and feel free to reach out.
        </p>
      </div>
    </main>
  );
};

export default MainContent;