import React, { useRef , useEffect } from 'react';

const Projects = ({ footerHeight }) => {
  const intellawyerRef = useRef(null);
  const aivetaalRef = useRef(null);
  const tradebotRef = useRef(null);
  const overcookedRef = useRef(null);
  const doomRef = useRef(null);

    useEffect(() => {
    if (window.location.hash === '#intellawyer') {
      intellawyerRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (window.location.hash === '#aivetaal') {
      aivetaalRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (window.location.hash === '#tradebot') {
      tradebotRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (window.location.hash === '#overcooked') {
      overcookedRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (window.location.hash === '#doom') {
      doomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);
  return (
    <main className="flex mt-16 mx-8">
      <div className="ml-8">
        <h1 className="text-2xl font-bold">Past Projects:</h1>
        <div ref={intellawyerRef}>
         <h2 id="intellawyer" name="intellawyer" className="text-lg font-bold mt-6">Founder, IntelLawyer.com</h2>
        <p className="text-lg mb-4" title="Discovery of the problem">
          {"Around September 2022, I stumbled upon an interesting fact while speaking with lawyers. It turned out that most existing legal search engines relied heavily on keyword searches. This got me thinking about how far search technology had come in the past decade, and an idea sparked in my mind - why not create a modern search engine specifically designed for legal judgments?"}
        </p>

        <p className="text-lg mb-4" title="Product development">
          {"Excited by the opportunity, I began developing a revolutionary search engine called IntelLawyer. My focus was on cases from the Supreme Court, Bombay High Court, and Bangalore High Court. The process involved downloading judgments using a web crawler, extracting essential information from PDFs using a custom parser, and leveraging sentence bert models to create document/query embeddings. To further enhance search results, I used a cross-encoder for re-ranking."}
        </p>
        <p className="text-lg mb-4" title="Cold start">
        {"Building IntelLawyer came with its fair share of challenges, and the biggest one was tackling the cold start problem when deploying the search engine on serverless infrastructure. However, I persevered and developed a minimum viable product (MVP). It was time to put it to the test!"}
        </p>
        <p className="text-lg mb-4" title="User acquisition ">
          {"Reaching out to lawyers and seeking their feedback proved to be a game-changer. The most effective approach was approaching them outside the courtroom and engaging in conversations. This strategy generated significant interest from a few lawyers, resulting in an initial user base of around 500 individuals. Unfortunately, retaining those users posed a challenge. Through conversations with lawyers and other legal search providers, I discovered that semantic search proved valuable for specific niche use cases, while for most other scenarios, a keyword-based search with manual ranking worked better."}
        </p>
        <p className="text-lg mb-4" title="What I learned">
          {"From this experience, I learned the importance of engaging with users and addressing user attrition issues. Following the release of chatGPT API, I realized that it could solve multiple other problems, leading me to make the decision to close down IntelLawyer.com and shift my focus to other endeavors. I am grateful for the users and advisors who supported me throughout this journey and I am excited to embark on new projects."}
        </p>
        <p className="text-lg mb-4" title="Thank you">
        {"I am incredibly grateful for the support and guidance I received from my users and advisors throughout this transformative journey. Now, I am brimming with excitement as I embark on fresh projects, eager to explore uncharted territories."}
        </p>
        <p className="text-lg mb-4" title="Answer engine">
          {"P.S. As a fun side note, I participated in a hackathon where I built an answer engine to help lawyers find answers about the Civil Procedure Code. The details of this endeavor can be found in this"} <a href="https://twitter.com/nirantk/status/1631299246715699200?s=46&t=PtXsyxI-ZtFT1qUEbUxlBQ." target="_blank" rel="noreferrer" className="text-blue-500 hover:underline"> tweet thread</a>
        </p>
        </div>
        <div ref={aivetaalRef}>
        <h2 id="aivetaal" className="text-lg font-bold mt-6">Founder, AIVetaal.com</h2>
        <p className="text-lg mb-4" title="Mission and Motivation">
          {"To make learning more personalized using Large Language Models, AIVetaal was started. It was around December 2021 when I observed the promising results achieved by models like GPT-3 and chatbots such as BlenderBot2. This inspired me to create a chatbot specifically designed to assist students in their preparation for competitive exams."}
        </p>

        <p className="text-lg mb-4" title="Deep Learning Models">
          {"During that time, the instruction tuning paper had not yet been released, so I utilized a basic Large Language Model known as T5 and incorporated retrieval capabilities through the Dense Passage Retrieval (DPR) Model. We trained the T5 model to generate questions and provide answers based on the given context. However, due to a lack of curated data, we couldn't train it to understand the students' answers and provide detailed feedback. Consequently, developing a closed-domain chatbot proved challenging. While we managed to secure a few contracts for question and answer generation, the revenue generated was not significant."}
        </p>
        <p className="text-lg mb-4" title="Model to solve Quantitative Aptitude questions">
          {"Recognizing the limitations we faced in chatbot development and realizing the relatively small market for question-answer generation, I made the decision to shift focus. We embarked on building a language model specifically tailored to solve quantitative aptitude questions. Through finetuning the T5 model, we achieved success in solving quantitative aptitude questions found in exams such as GRE and CAT. Although we curated a few thousand pairs of mathematical questions and answers, it became evident that a more substantial amount of data was necessary to develop a reliable model. Unfortunately, the cost associated with collecting additional data exceeded our resources, and we were compelled to halt our efforts."}
        </p>
        <p className="text-lg mb-4" title="What I learned">
          {"These projects taught me the significance of setting smaller, achievable goals that cater to a market willing to invest, while still working towards a larger and more ambitious objective."}
        </p>
        </div>
        <div ref={tradebotRef}>
        <h2 id="tradebot" className="text-lg font-bold mt-6">Developer, Agents for equity trading</h2>
        <p className="text-lg mb-4" title="Mission and Motivation">
          {"Driven by the need to mitigate market impact and losses resulting from large-scale stock trading, JP Morgan Chase started an AI acceleration group and tasked us to simulation platform coupled with specialized agents specifically tailored for stock trading, aiming to minimize market impact."}
        </p>

        <p className="text-lg mb-4" title="Simulator">
          {"The development of the simulation platform began with an extensive exploration of deep learning models capable of analyzing the intricate patterns within stock prices over time. By studying the historical data, these models sought to capture the underlying dynamics and trends in the market, allowing for more informed decision-making. In addition to the deep learning models, we delved into the realm of multi-agent systems. We aimed to construct agents that could replicate the patterns observed in stock prices over time, enabling more accurate predictions and simulations."}
        </p>
        <p className="text-lg mb-4" title="Agents">
          {"Recognizing the diverse time intervals at which equity trading occurs, we focused on developing a collection of agents that could operate effectively across different timescales. We understood that trading stocks involved navigating continuous control challenges, but to simplify the process, we divided the actions into discrete steps. This approach allowed for a more granular analysis of the market and facilitated decision-making by the agents. To construct these agents, we explored multiple algorithms, including DDPG (Deep Deterministic Policy Gradient), A2C (Advantage Actor-Critic), and PPO (Proximal Policy Optimization). By leveraging these algorithms, the agents gained the ability to examine the order book and derive key features relevant to decision-making. Equipped with this information, they were able to make informed trading decisions aimed at minimizing market impact, guided by the reward function defined within the simulator."}
        </p>
        </div>
        <div ref={overcookedRef}>
        <h2 id="overcooked" className="text-lg font-bold mt-6">Primary Developer, Agent to play Overcooked game</h2>
        <p className="text-lg mb-4" title="Mission and Motivation">
          {"At Unity, we embarked on an exciting project to showcase the capabilities of Reinforcement Learning agents by incorporating them into the cooperative cooking game, Overcooked. As developers of the game engine, we aimed to harness the potential of these agents for game testing and the development of non-playing characters, enhancing the overall player experience."}
        </p>

        <p className="text-lg mb-4" title="State Representation">
          To bring this vision to life, we gained access to the game state of Overcooked, enabling us to analyze and understand the underlying dynamics of the gameplay. In our pursuit of representing the game state effectively, we chose to utilize natural language as a means of capturing the intricacies of the game mechanics and interactions.
        </p>
        <p className="text-lg mb-4" title="Agent">
          To train our agent, we employed a technique called Trial-based Heuristic Tree Search. This approach allowed us to generate a comprehensive dataset of action-values, reflecting the agent's evaluation of different actions within the game. These action-values served as a foundation for training a neural network, which was designed to predict the optimal actions to be taken in various game scenarios. With an impressive test accuracy of over 90%, our agent proved its mettle by successfully playing the game of Overcooked.
        </p>
        </div>
        <div ref={doomRef}>
        <h2 id="doom" className="text-lg font-bold mt-6">Primary Developer, Agent to play Doom game</h2>
        <p className="text-lg mb-4" title="Mission and Motivation">
          During the early stages of my learning deep learning, I started working on a fascinating project in 2016. At that time, the successful training of a Deep Reinforcement Learning Agent to play the Doom game was a rare accomplishment achieved by only a handful of teams. Eager to push the boundaries of what was possible, I took on the challenge of training such an agent myself.
        </p>
        <p className="text-lg mb-4" title="Agents">
          To train the Deep Reinforcement Learning Agent, I delved into writing scripts for the DQN (Deep Q-Network) and A3C (Asynchronous Advantage Actor-Critic) algorithms using Lua Torch. These algorithms served as the foundation for training the agent, allowing it to learn and improve its gameplay skills through reinforcement learning.
        </p>
        <p className="text-lg mb-4" title="Emergent Behavior">
            Throughout the training process, I observed some intriguing emergent behavior from the agent. It exhibited an ability to strategically hide in corners, utilizing the environment to its advantage, before launching precise and strategic attacks on enemies. Witnessing these adaptive and intelligent behaviors within the agent was truly captivating, and it highlighted the immense potential of deep reinforcement learning in gaming. If you are curious to witness the progress of my early version of the agent, I invite you to visit my <a href="https://www.youtube.com/watch?v=yMFDXfYYaqU" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">YouTube</a> channel. There, you will find a demo showcasing the agent performing a health pack gathering task.
        </p>
        </div>

      </div>

    </main>
  );
};

export default Projects;